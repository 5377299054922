import React from 'react';
import { Footer, Link } from 'src/components';
import emails from 'src/constants/emails';
import paths from 'src/constants/paths';
import phoneNumbers from 'src/constants/phone-numbers';
import urls from 'src/constants/urls';
import { formatUrl, $t } from 'src/utils';

export default function AetnaFooter() {
  return (
    <Footer
      disclaimer={$t({
        id: 'aetna.footer.disclaimer',
        defaultMessage: `This website does not display all Qualified Health Plans (QHPs) available through {healtcareGovLink}. To see all available QHP options, go to the Health Insurance Marketplace® website at {healtcareGovLink} or visit {catchLink}.`,
        data: {
          healtcareGovLink: <Link to={urls.HEALTHCARE_GOV}>HealthCare.gov</Link>,
          catchLink: <Link to={paths.EXPLORER}>{formatUrl(paths.EXPLORER)}</Link>,
        },
      })}
      email={emails.AETNA}
      phoneNumber={phoneNumbers.AETNA}
      phoneNumberFormatted={phoneNumbers.AETNA_FORMATTED}
    />
  );
}
